import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/styles/index.scss'
import TheLoader from './components/TheLoader.vue'
import PopupTemplate from './components/PopupTemplate.vue'
import currency from './helpers/currency'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import VModal from 'vue-js-modal'
import mitt from 'mitt'
const emitter = mitt()
import VueDragscroll from 'vue-dragscroll'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import * as Sentry from "@sentry/vue";
currency()

import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
Vue.use(VuejsDialog)

LicenseManager.setLicenseKey(
  'ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2'
)

const socketHost = process.env.VUE_APP_WEBSOCKET_URL + '?token=' + process.env.VUE_APP_WEBSOCKET_TOKEN
console.log(process.env.VUE_APP_APP_VERSION)

Vue.config.productionTip = false
Vue.use(PerfectScrollbar)
Vue.use(VueDragscroll)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VModal, {
  dynamicDefaults: {
    draggable: false,
    resizable: true,
    height: 'auto',
    width: '500px'
  }
})

// VueSax
import 'material-icons/iconfont/material-icons.css'

// CWA Styles
import '@/agGridV2/styles/ag-grid.scss'

async function createApplication() {
  await store.dispatch('fetchActiveUser')
  await store.dispatch('loadAndSetPlatforms')
  await store.dispatch('loadAndSetDefaultLeadCenterDate')
  Vue.prototype.$emitter = emitter
  Vue.prototype.$redirectTo = function (path) {
    if (this.$route.path !== path) {
      this.$router.push(path)
    }
  }
  Vue.prototype.$navigateTo = function (routeName) {
    if (this.$route.name !== routeName) {
      this.$router.push({ name: routeName }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    }
  }
  Vue.prototype.$socket = new WebSocket(socketHost)
  Vue.component('TheLoader', TheLoader)
  Vue.component('PopupTemplate', PopupTemplate)

  Sentry.init({
    Vue,
    dsn: "https://14751fa2551183f5de4dce1f72ca6156@o497041.ingest.us.sentry.io/4507690487971840",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}
createApplication()
