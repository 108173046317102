import { showSnackbar } from '@/services'
import axios from 'axios'

// For gateway integration type
const gatewayRequest = (url, method, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let fullUrl = url === 'tasks/submit-timelog' ? process.env.VUE_APP_GATEWAY + '/' + url.replace('tasks/', '') : process.env.VUE_APP_GATEWAY + '/core/' + url
      if (method === 'GET' && data) {
        const jsonString = JSON.stringify(data)
        const encodedJson = encodeURIComponent(jsonString)
        fullUrl += '?data=' + encodedJson
      }
      const object = {
        baseURL: fullUrl,
        method: method,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'x-gateway-authorization': process.env.VUE_APP_GATEWAY_KEY
        }
      }
      if (localStorage.getItem('user_id')) {
        object.headers['x-user-id'] = localStorage.getItem('user_id')
      }

      if (localStorage.getItem('accessToken')) {
        object.headers['authorization'] =
          'Bearer ' + localStorage.getItem('accessToken')
      }
      if (data && method !== 'GET') {
        object.data = data
      }
      const response = await axios.request(object)
      resolve(response.data)
    } catch (err) {
      if (err?.response?.status !== 401) {
        let message = ''
        if (err.response) {
          if (err.response.data.message) {
            message = err.response.data.message
          } else {
            message = JSON.stringify(err.response.data)
          }
        } else {
          message = err.message
        }
        showSnackbar({
          text: message,
          mode: 'error'
        })
        return reject(err)
      } else {
        resolve({})
      }
    }
  })
}
const gatewayServicesRequest = (service, url, method, data) => {
  const serviceUrl = process.env.VUE_APP_GATEWAY + `/${service}`

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let fullUrl = serviceUrl + '/' + url
      if (method === 'GET' && data) {
        const jsonString = JSON.stringify(data)
        const encodedJson = encodeURIComponent(jsonString)
        fullUrl += '?data=' + encodedJson
      }
      const object = {
        baseURL: fullUrl,
        method: method,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'x-gateway-authorization': process.env.VUE_APP_GATEWAY_KEY
        }
      }
      object.headers['x-api-key'] = process.env.VUE_APP_MT_API_KEY
      if (data && method !== 'GET') {
        object.data = data
      }
      const response = await axios.request(object)
      resolve(response.data)
    } catch (err) {
      if (err?.response?.status !== 401) {
        let message = ''
        if (err.response) {
          if (err.response.data.message) {
            message = err.response.data.message
          } else {
            message = JSON.stringify(err.response.data)
          }
        } else {
          message = err.message
        }
        showSnackbar({
          text: message,
          mode: 'error'
        })
        return reject({ message })
      } else {
        resolve({})
      }
    }
  })
}

// For differect integration type
const differectRequest = (url, method, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let fullUrl = url === 'tasks/submit-timelog' ? process.env.VUE_APP_MT_API_SYNC + '/' + url.replace('tasks/', '') : process.env.VUE_APP_MT_API + '/' + url
      if (method === 'GET' && data) {
        const jsonString = JSON.stringify(data)
        const encodedJson = encodeURIComponent(jsonString)
        fullUrl += '?data=' + encodedJson
      }
      const object = {
        baseURL: fullUrl,
        method: method,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
      if (localStorage.getItem('user_id')) {
        object.headers['x-user-id'] = localStorage.getItem('user_id')
      }

      if (localStorage.getItem('accessToken')) {
        object.headers['authorization'] =
          'Bearer ' + localStorage.getItem('accessToken')
      }
      if (data && method !== 'GET') {
        object.data = data
      }
      const response = await axios.request(object)
      resolve(response.data)
    } catch (err) {
      if (err?.response?.status !== 401) {
        let message = ''
        if (err.response) {
          if (err.response.data.message) {
            message = err.response.data.message
          } else {
            message = JSON.stringify(err.response.data)
          }
        } else {
          message = err.message
        }
        showSnackbar({
          text: message,
          mode: 'error'
        })
        return reject(err)
      } else {
        resolve({})
      }
    }
  })
}
const SERVICES = {
  'activities-processor': process.env.VUE_APP_MT_ACTIVITIES_PROCESSOR,
  'lead-extractor': process.env.VUE_APP_MT_LEAD_EXTRACTOR,
  'content-tool': process.env.VUE_APP_MT_API_CONTENT_TABLE + '/content-tool'
}
const differectServicesRequest = (service, url, method, data) => {
  const serviceUrl = SERVICES[service] || ''
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let fullUrl = serviceUrl + '/' + url
      if (method === 'GET' && data) {
        const jsonString = JSON.stringify(data)
        const encodedJson = encodeURIComponent(jsonString)
        fullUrl += '?data=' + encodedJson
      }
      const object = {
        baseURL: fullUrl,
        method: method,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
      object.headers['x-api-key'] = process.env.VUE_APP_MT_API_KEY
      if (data && method !== 'GET') {
        object.data = data
      }
      const response = await axios.request(object)
      resolve(response.data)
    } catch (err) {
      if (err?.response?.status !== 401) {
        let message = ''
        if (err.response) {
          if (err.response.data.message) {
            message = err.response.data.message
          } else {
            message = JSON.stringify(err.response.data)
          }
        } else {
          message = err.message
        }
        showSnackbar({
          text: message,
          mode: 'error'
        })
        return reject({ message })
      } else {
        resolve({})
      }
    }
  })
}

let request = gatewayRequest
let servicesRequest = gatewayServicesRequest
if (process.env.VUE_APP_API_TYPE === 'different') {
  request = differectRequest
  servicesRequest = differectServicesRequest
}

export default {
  // Helpers
  getFilters(data) {
    return request('composite/filters', 'GET', data)
  },
  // Clients
  getClients() {
    return request('clients', 'GET')
  },
  updateClient(_id, data) {
    return request('clients/' + _id, 'PATCH', data)
  },
  agGridClientsSettings(filter) {
    return request('composite/views/agGrid/clients-settings', 'POST', filter)
  },
  agGridTeamChanges(filter) {
    return request('composite/views/agGrid/team-changes', 'POST', filter)
  },
  agGridAccountingDashboard(filter) {
    return request(
      'composite/views/agGrid/accounting-dashboard',
      'POST',
      filter
    )
  },
  agGridCommission(filter) {
    return request('composite/views/agGrid/commission', 'POST', filter)
  },
  agGridInvoices(filter) {
    return request('composite/views/agGrid/invoices', 'POST', filter)
  },
  clientReport(data) {
    return request('clients/report', 'POST', data)
  },
  // Projects
  getProjects() {
    return request('projects', 'GET')
  },
  getFirstMonthProject(data) {
    return request('projects/first-month-project', 'GET', data)
  },
  updateProject(_id, data) {
    return request('projects/' + _id, 'PATCH', data)
  },
  agGridProjectsSettings(filter) {
    return request('composite/views/agGrid/projects-settings', 'POST', filter)
  },
  agGridProjectsExpenses(filter) {
    return request('composite/views/agGrid/expenses', 'POST', filter)
  },
  agGridClientProjectsExpenses(filter) {
    return request('projects/views/agGrid/client-expenses', 'POST', filter)
  },
  // Staffs
  getStaffs() {
    return request('staffs', 'GET')
  },
  getStaffsByActivityTag() {
    return request('staffs/by-activity-tags', 'GET')
  },
  agGridUsersSettings(filter) {
    return request('composite/views/agGrid/users-settings', 'POST', filter)
  },
  updateStaff(_id, data) {
    return request('staffs/' + _id, 'PATCH', data)
  },
  // Platforms
  getPlatform(_id, data) {
    return request(`platforms/${_id}`, 'GET', data)
  },
  getPlatforms(data) {
    return request('platforms', 'GET', data)
  },
  updatePlatform(_id, data) {
    return request('platforms/' + _id, 'PATCH', data)
  },
  getBusinessMetrics(_id, data) {
    return request('platforms/' + _id + '/business-metrics', 'GET', data)
  },
  getMonthForKpi(_id) {
    return request(`platforms/${_id}/getMonthForKpi`, 'GET')
  },
  // GPT
  getGptTasks() {
    return request('tools/gpt/tasks', 'GET')
  },
  deleteGptTask(_id) {
    return request('tools/gpt/tasks/' + _id, 'DELETE')
  },
  refreshGptTask(_id, data) {
    return request('tools/gpt/tasks/' + _id, 'PATCH', data)
  },
  addGptTask(data) {
    return request('tools/gpt/tasks', 'POST', data)
  },
  // Activity Tags
  getActivityTags() {
    return request('activity-tags', 'GET')
  },
  // Analytics
  agGridProjectsAnalytics(filter) {
    return request('composite/views/agGrid/projects-analytics', 'POST', filter)
  },
  agGridProjectsAnalyticsV2() {
    return request('analytics/projects', 'GET')
  },
  getProjectsAnalyticsFilters(data) {
    return request('analytics/projects-analytics/filters', 'GET', data)
  },
  getCommissionsAnalyticsInvoices(data) {
    return request('analytics/commissions-analytics/invoices', 'GET', data)
  },
  generateAnalytics(data) {
    return request('analytics/generate', 'GET', data)
  },
  // Activities
  agGridActivities(filter) {
    return request('composite/views/agGrid/activities', 'POST', filter)
  },
  getActivity(_id) {
    return request(`activities/${_id}`, 'GET')
  },
  getActivitiesByFilter(filter) {
    return request(`activities/by-filter`, 'GET', { filter: filter })
  },
  updateActivity(_id, data) {
    return request(`activities/${_id}`, 'PATCH', data)
  },
  deleteActivity(_id) {
    return request(`activities/${_id}`, 'DELETE')
  },
  getProcessedCount(platform) {
    return request(`activities/processed-count/${platform}`, 'GET')
  },
  getActivitiesPriceEstimate(data) {
    return request(`activities/price-estimate`, 'POST', data)
  },
  exportActivities(data) {
    return request(`activities/export`, 'POST', data)
  },
  getStartEndDates(data) {
    return request(`activities/start-end-dates`, 'GET', data)
  },
  refreshForProcess(data) {
    return servicesRequest(
      'activities-processor',
      `activities-processor/refresh-for-process`,
      'POST',
      data
    )
  },
  getActivitiesFilters(data) {
    return request(`activities/filters`, 'GET', data)
  },
  workflowProcess(data) {
    return servicesRequest(
      'activities-processor',
      `activities-processor/workflow-process`,
      'POST',
      data
    )
  },
  runScenario(data) {
    return servicesRequest(
      'activities-processor',
      'activities-processor/scenario-process',
      'POST',
      data
    )
  },
  cancelScenario(data) {
    return servicesRequest(
      'activities-processor',
      'activities-processor/scenario-cancel',
      'POST',
      data
    )
  },
  // Configs
  getConfigs(data) {
    return request('configs', 'GET', data)
  },
  createConfig(data) {
    return request('configs', 'POST', data)
  },
  // Anton
  getFolders() {
    return request('projects/get-folders', 'GET')
  },
  getTimeEntries(data) {
    return request('tasks/time-entries', 'POST', data)
  },
  getTeamTimeLog(data) {
    return request('tasks/team-time-log', 'POST', data)
  },
  getTaskStatuses() {
    return request('tasks/task-statuses', 'GET')
  },
  submitTimelog(data) {
    return request('tasks/submit-timelog', 'POST', data)
  },
  sendReport(data) {
    return request('tasks/send-report', 'POST', data)
  },
  getTasksTeamLog(data) {
    return request('tasks/tasks-timelog', 'POST', data)
  },
  login(data) {
    return request('auth/login', 'POST', data)
  },
  getCurrentUser() {
    return request('staffs/me', 'GET')
  },
  logout() {
    return request('auth/logout', 'GET')
  },
  getTasksByProject(params) {
    return request('tasks/tasks-by-project', 'POST', params)
  },
  registerUser(data) {
    return request('auth/register', 'POST', data)
  },
  forgotPassword(data) {
    return request('auth/forgot-password', 'POST', data)
  },
  resetPassword(data) {
    return request('auth/reset-password', 'POST', data)
  },
  // Contacts
  agGridContacts(filter) {
    return request('composite/views/agGrid/contacts', 'POST', filter)
  },
  agGridContactsCharts(filter) {
    return request('composite/views/agGrid/contacts-charts', 'POST', filter)
  },
  getContactActivities(_id) {
    return request(`contacts/${_id}/activities`, 'GET')
  },
  getContactsFilters(data) {
    return request(`contacts/filters`, 'GET', data)
  },
  updateContact(_id, data) {
    return request('contacts/' + _id, 'PATCH', data)
  },
  // Journal
  getJournalRecords(data) {
    return request(`journals`, 'GET', data)
  },
  // Kpis
  agGridKpi(data) {
    return request(`composite/views/agGrid/kpi`, 'POST', data)
  },
  // Applications
  checkApplication(data) {
    return request(`applications/check-application`, 'POST', data)
  },
  applicationsSettings() {
    return request(`applications/settings`, 'GET')
  },
  newApplicationsSettings() {
    return request(`applications/new-settings`, 'GET')
  },
  checkNewApplication(data) {
    return request(`applications/check-new`, 'POST', data)
  },
  generateOuth2Link(link, data) {
    return request(link, 'POST', data)
  },
  applicationSyncArchived(data) {
    return request(`applications/sync-archived`, 'POST', data)
  },
  applicationsConfigs() {
    return request(`applications/configs`, 'GET')
  },
  syncApplication(data) {
    return servicesRequest(
      'lead-extractor',
      `lead-extractor/sync-application`,
      'POST',
      data
    )
  },
  getModules() {
    return servicesRequest('lead-extractor', `lead-extractor/modules`, 'GET')
  },
  // Lead Center
  agGridLeadCenterOverview(filter) {
    return request(
      'composite/views/agGrid/lead-center-overview',
      'POST',
      filter
    )
  },
  // Workflows
  getWorkflows() {
    return request('workflows', 'GET')
  },
  getBlocks() {
    return request('workflows/blocks', 'GET')
  },
  getScenarios() {
    return request('workflows/scenarios', 'GET')
  },
  getWorkflow(_id) {
    return request('workflows/' + _id, 'GET')
  },
  createOrUpdateWorkflow(data) {
    return request('workflows', 'POST', data)
  },
  createOrUpdateBlock(data) {
    return request('workflows/blocks', 'POST', data)
  },
  deleteWorkflow(_id) {
    return request('workflows/' + _id, 'DELETE')
  },
  deleteBlock(_id) {
    return request('workflows/blocks/' + _id, 'DELETE')
  },
  getWorkflowsOptions() {
    return request('composite/workflows-options', 'GET')
  },

  // Content Tool
  // Content Tool - Records
  getContentRecords() {
    return servicesRequest('content-tool', 'records', 'GET')
  },
  createContentRecord(data) {
    return servicesRequest('content-tool', 'records', 'POST', data)
  },
  removeContentRecord(id) {
    return servicesRequest('content-tool', `records/${id}`, 'DELETE')
  },
  removeContentRecords(ids) {
    return servicesRequest('content-tool', 'records', 'DELETE', { ids })
  },
  updateContentRecord(id, data) {
    return servicesRequest('content-tool', `records/${id}`, 'PATCH', data)
  },
  cleanUpContentRecords(ids) {
    return servicesRequest('content-tool', `records/clean-up`, 'PUT', { ids })
  },
  // Content Tool - Projects
  getContentProjects() {
    return servicesRequest('content-tool', 'projects', 'GET')
  },
  // Content Tool - Editors
  getContentEditors() {
    return servicesRequest('content-tool', 'editors', 'GET')
  },
  // Content Tool - Selectors
  getContentSelectors() {
    return servicesRequest('content-tool', 'selectors', 'GET')
  },
  // Content Tool - Authorize
  contentToolAuth(userId) {
    return servicesRequest('content-tool', 'login', 'POST', { userId })
  }
}

// While there is no integration with authorization
// from the Core server, I made a class to interact
// with my API so as not to produce crutches

export class ContentToolClient {
  _client
  _socket
  _me
  _pingInterval

  constructor(client, me) {
    this._client = client
    this._me = me
  }

  static async create({ id, name }) {
    const baseUrl = process.env.VUE_APP_MT_API_CONTENT_TABLE + '/content-tool'
    // authorize
    const response = await axios.request({
      baseURL: baseUrl + '/login',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      data: {
        userId: id
      }
    })

    // check auth
    if (response.status !== 200) {
      console.error(response.error)
      return
    }

    // get api key
    const { apiKey } = response.data

    // create http client
    const client = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-api-key': apiKey
      }
    })
    return new ContentToolClient(client, {
      id,
      name
    })
  }

  // connect socket
  socketConnect(socketHandler) {
    try {
      const baseUrl = process.env.VUE_APP_MT_API_CONTENT_TABLE + '/content-tool'
      // open connection
      const socket = new WebSocket('wss://' + new URL(baseUrl).host)
      socket.onopen = (event) => {
        socket.send(
          JSON.stringify({
            type: 'register',
            data: { name: this._me.name }
          })
        )
        this._startPing()
      }
      socket.onclose = (event) => {
        console.log('Connection closed', event.reason)
        this._stopPing()
      }
      socket.onerror = (err) => {
        console.error('[websocket]', err)
      }
      // set message handler
      socket.onmessage = socketHandler
      this._socket = socket
      // return successful connection
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  _startPing() {
    this._pingInterval = setInterval(() => {
      if (this._socket.readyState === WebSocket.OPEN) {
        this._socket.send('ping')
      }
    }, 30000)
  }

  _stopPing() {
    clearInterval(this._pingInterval)
  }

  // http client work
  records = {
    get: async () => {
      const res = await this._client.get('/records')
      return res.data
    },
    create: async (data) => {
      const res = await this._client.post('/records', data)
      const newData = res.data.map((item) => {
        const { __v, ...d } = item
        return d
      })
      this._socket.send(
        JSON.stringify({
          type: 'modify',
          data: { action: 'add', data: newData }
        })
      )
      return newData
    },
    remove: async (id) => {
      const res = await this._client.delete(`/records/${id}`)
      const idList = [{ _id: id }]
      this._socket.send(
        JSON.stringify({
          type: 'modify',
          data: { action: 'remove', data: idList }
        })
      )
      return res.data
    },
    removeMany: async (ids) => {
      const res = await this._client.delete('/records', { ids })
      const idList = ids.map((id) => {
        return { _id: id }
      })
      this._socket.send(
        JSON.stringify({
          type: 'modify',
          data: { action: 'remove', data: idList }
        })
      )
      return res.data
    },
    update: async (id, data) => {
      const res = await this._client.patch(`/records/${id}`, data)
      const { __v, ...newData } = res.data
      this._socket.send(
        JSON.stringify({
          type: 'modify',
          data: { action: 'update', data: [newData] }
        })
      )
      return newData
    },
    clean: async (data) => {
      const ids = data.map((item) => item._id)
      const res = await this._client.put('/records/clean-up', { ids })
      this._socket.send(
        JSON.stringify({
          type: 'modify',
          data: { action: 'update', data }
        })
      )
      return res.data
    },
    countWords: async (id, finalContent) => {
      const res = await this._client.post('/records/count-words/' + id, {
        finalContent
      })
      return res.data
    },
    batchStatusUpdate: async (ids, status) => {
      const res = await this._client.post('/records/batch-status-update', {
        ids,
        status
      })
      return res.data
    }
  }

  projects = {
    get: async () => {
      const res = await this._client.get('/projects')
      return res.data
    }
  }

  editors = {
    get: async () => {
      const res = await this._client.get('/editors')
      return res.data
    }
  }

  selectors = {
    get: async () => {
      const res = await this._client.get('/selectors')
      return res.data
    }
  }

  // http client work end
  socket = {
    lockCell: (cell) => {
      this._socket.send(
        JSON.stringify({
          type: 'lockCell',
          data: { cell }
        })
      )
    },
    unlockCell: (cell) => {
      this._socket.send(
        JSON.stringify({
          type: 'unlockCell',
          data: { cell }
        })
      )
    }
  }
  // socket work
  // socket work end

  destroy() {
    if (
      this._socket &&
      (this._socket.readyState === WebSocket.OPEN ||
        this._socket.readyState === WebSocket.CONNECTING)
    ) {
      this._socket.close()
    }
    this._client = null
    this._socket = null
  }
}
