import { showSnackbar } from '@/services/snackbar-service'
import { addDays, startOfDay, subDays, format, isEqual } from 'date-fns'
import Vue from 'vue'
import clone from 'rfdc/default'
import TimeConverter from '@/services/convert-time-service'
import { groupBy } from '@/services/helpers'
import _ from 'lodash'
import { zonedTimeToUtc } from 'date-fns-tz'
var dayjs = require('dayjs')
let utc = require('dayjs/plugin/utc')
import mtApi from '@/agGridV2/helpers/mt-api.helper'

dayjs.extend(utc)

window.zonedTimeToUtc = zonedTimeToUtc
window.dayjs = dayjs

export default {
  state: {
    statuses: {
      NEW: 'new',
      UPDATE: 'update',
      INITIAL: 'initial'
    },
    folders: [],
    backupedFolders: [],
    folderList: [],
    week: {
      startDate: '',
      endDate: ''
    },
    weekValue: null,
    totalCol: [],
    reportTaskList: [],
    reportMode: '',
    reportDate: '',
    taskStatuses: [],
    generalStatuses: []
  },
  mutations: {
    setWeek(state, weekObject) {
      state.week = weekObject.range
      state.weekValue = `${format(
          weekObject.range.startDate,
          'MM/dd/yyyy'
      )} - ${format(subDays(weekObject.range.endDate, 1), 'MM/dd/yyyy')}`
    },
    setWeekValue(state, week) {
      state.weekValue = week
    },
    setFolderList(state, folders) {
      state.folderList = folders
    },
    setFolders(state, { folders, daysWeek, copyWeek = false, status }) {
      state.folders = folders.map((folder) => {
        folder.tasks.map((task) => {
          const formattedTimes = task.time_entries.map((timeEntry) => {
            return {
              id: timeEntry.id,
              // at: new Date(timeEntry.start), // TODO: last test
              // at: new Date(dayjs(timeEntry.start).subtract(timeEntry.duration, 'millisecond').toISOString()),
              at: new Date(dayjs(timeEntry.start).toISOString()), // делать subtract если duration > UTC hour ???
              duration: Number(timeEntry.duration)
            }
          })

          task.time_entries = daysWeek.map((day) => {
            const timeEntriesFromCurrentDay = formattedTimes.filter((time) => {
              return isEqual(startOfDay(time.at), day)
            })

            if (timeEntriesFromCurrentDay.length > 1) {
              let ids = timeEntriesFromCurrentDay.map((time) => {
                return time.id
              })

              if (copyWeek && status === state.statuses.INITIAL) {
                ids = []
              }

              return timeEntriesFromCurrentDay.reduce((prev, cur) => {
                const computedDuration = prev.duration + cur.duration || null

                return {
                  duration: computedDuration,
                  initDuration:
                      status === state.statuses.NEW ? null : computedDuration,
                  ids: status === state.statuses.NEW ? [] : ids,
                  status,
                  at: day
                }
              })
            } else {
              let sIds = []
              if (copyWeek && status === state.statuses.INITIAL) {
                sIds = []
              } else if (timeEntriesFromCurrentDay[0]?.id && status !== state.statuses.NEW) {
                sIds = [timeEntriesFromCurrentDay[0].id]
              }
              return {
                duration: timeEntriesFromCurrentDay[0]?.duration || null,
                initDuration:
                    timeEntriesFromCurrentDay[0]?.duration && status !== state.statuses.NEW
                        ? timeEntriesFromCurrentDay[0]?.duration
                        : null,
                ids:
                    timeEntriesFromCurrentDay[0]?.id && status !== state.statuses.NEW
                        ? sIds
                        : [],
                status: timeEntriesFromCurrentDay[0]?.duration ? status : state.statuses.INITIAL,
                at: day
              }
            }
          })
          task.status = state.statuses.INITIAL
          return task
        })
        folder.status = state.statuses.INITIAL
        return folder
      })
    },
    addFolder(state, folder) {
      state.folders.push(folder)
    },
    addTaskToFolder(state, task) {
      const findedFolder = state.folders.find((folder) => {
        return folder.id === task.folder_id
      })
      findedFolder && findedFolder.tasks.push(task)
    },
    updateTime(state, { taskId, position, duration }) {
      let task = null
      state.folders.forEach((folder) => {
        folder.tasks.forEach((currentTask) => {
          if (currentTask.id === taskId) {
            task = currentTask
          }
        })
      })
      if (task === null) {
        console.warn('Task not found with id', taskId)
        return false
      }
      const taskCell = task.time_entries[position]
      const prev_duration = taskCell.duration
      const prev_status = taskCell.status
      // console.log('duration', duration)
      // console.log('prev_duration', prev_duration)
      // console.log('prev_status', prev_status)
      if ('initDuration' in taskCell && taskCell.initDuration === duration) {
        Vue.set(taskCell, 'duration', duration)
        Vue.set(taskCell, 'status', state.statuses.INITIAL)
        return false
      } else if (!('initDuration' in taskCell)) {
        taskCell['initDuration'] = prev_duration
      }
      if (duration === false && duration !== prev_duration) {
        Vue.set(taskCell, 'duration', prev_duration)
        Vue.set(taskCell, 'status', prev_status)
      } else if (duration !== false) {
        Vue.set(taskCell, 'duration', duration)
        if (prev_duration !== duration) {
          Vue.set(taskCell, 'status', state.statuses.NEW)
        }
      }
    },
    updateTimeId(state, { taskId, position, id }) {
      const tasks = state.folders
        .map((folder) => {
          return folder.tasks
        })
        .flat()
      const task = tasks.find((task) => task.id === taskId)
      if (task) {
        console.log('updateTimeId', task)
        const timeEntry = task.time_entries[position]
        timeEntry.ids = id ? [id] : []
      } else {
        console.warn('Task not found: ', taskId)
      }
    },
    resetStatuses(state, folders) {
      folders.forEach(folder => {
        folder.status = state.statuses.INITIAL

        folder.tasks.forEach(task => {
          task.status = state.statuses.INITIAL

          task.time_entries.forEach(timeEntry => {
            timeEntry.status = state.statuses.INITIAL
          })
        })
      })
    },
    removeEmptyRows(state, folders) {
      folders.forEach(folder => {
        folder.tasks = folder.tasks.filter((task) => {
          let isEmptyDuration = task.time_entries.every(timeEntry => timeEntry.duration === null)

          return !isEmptyDuration
        })
      })
    },
    setBackup(state) {
      state.backupedFolders = clone(state.folders)
    },
    resetTimeCells(state) {
      state.folders = state.folders.filter((folder) => {
        folder.tasks = folder.tasks.filter((task) => {
          task.time_entries = task.time_entries.map((timeEntry) => {
            timeEntry.duration = timeEntry.initDuration
            timeEntry.status = state.statuses.INITIAL
            return timeEntry
          })
          return task.status === state.statuses.INITIAL
        })
        return folder.status === state.statuses.INITIAL
      })
    },
    clearTaskRow(state, { taskId, allTasks }) {
      const task = allTasks.find((task) => {
        return task.id === taskId
      })
      if (!task) {
        console.warn('Task not found:' + taskId)
        return false
      }
      const notNullableTimes = task.time_entries.filter((timeEntry) => {
        return timeEntry.duration !== null
      })
      if (notNullableTimes.length) {
        notNullableTimes.forEach((timeEntry) => {
          timeEntry.duration = null
          if (timeEntry.initDuration === null) {
            timeEntry.status = state.statuses.INITIAL
          } else {
            timeEntry.status = state.statuses.NEW
          }
        })
      } else {
        const notValidated = task.time_entries.filter((timeEntry) => {
          return (
              timeEntry.initDuration !== null ||
              timeEntry.status !== state.statuses.INITIAL ||
              timeEntry.duration !== null
          )
        })
        if (!notValidated.length) {
          state.folders.forEach((folder) => {
            folder.tasks.forEach((innerTask, index, object) => {
              if (innerTask.id === task.id) {
                object.splice(index, 1)
              }
            })
          })
        }
      }
    },
    replaceInitDuration(state) {
      state.folders.forEach((folder) => {
        folder.tasks.forEach((task) => {
          task.time_entries.forEach((timeEntry) => {
            timeEntry.initDuration = timeEntry.duration
          })
        })
      })
    },
    setReportTable(state, folders) {
      const tasks = folders.map((folder) => folder.tasks).flat()

      state.reportTaskList = tasks.map((task) => {
        task.folder_name = task.folder_name.split(' | ')[0]
        task.complete.value.string = task.complete.value.current + '%'
        task.complete.status = state.statuses.INITIAL

        task.status.value = {
          code: 0,
          label: task.general_status.status
        }

        // const currentStatus = state.taskStatuses.find(
        //     (status) => status.orderindex === task.status.value
        // )

        // if (currentStatus) {
        //   task.status.value = {
        //     code: currentStatus.orderindex,
        //     label: 'IN ' + currentStatus.name
        //   }
        // } else {
        //   task.status.value = {
        //     label: 'IN',
        //     code: -1
        //   }
        // }

        task.status.status = state.statuses.INITIAL
        task.general_status.updated_status = state.statuses.INITIAL

        const time = _.sumBy(task.time_entries, (item) => {
          return Number(item.duration)
        })

        // const time = task.time_entries.reduce((acc, cur) => {
        //   return acc + Number(cur.duration)
        // }, 0)

        task.initial = {
          status: Object.assign({}, task.status.value),
          general_status: Object.assign({}, task.general_status),
          complete: Object.assign({}, task.complete.value)
        }
        task.time = {
          string: TimeConverter.millisecondsToTime(time),
          value: time
        }
        return task
      })
    },

    setTaskStatuses(state, statuses) {
      const emptyTask = {
        name: 'IN',
        orderindex: -1
      }
      state.taskStatuses = [emptyTask, ...statuses];
    },
    setGeneralStatuses(state, statuses) {
      state.generalStatuses = statuses;
    },
    updateReportStatus(state, { taskId, value, status }) {
      const task = state.reportTaskList.find((task) => task.id === taskId)
      if (task) {
        task.status.value = value
        task.status.status = status
      } else {
        console.warn('Task not found: ', taskId)
      }
    },
    updateReportGeneralStatus(state, { taskId, value, status }) {
      const task = state.reportTaskList.find((task) => task.id === taskId)
      if (task) {
        task.general_status.status = value
        task.general_status.updated_status = status
      } else {
        console.warn('Task not found: ', taskId)
      }
    },
    updateReportComplete(state, { taskId, value, status }) {
      const task = state.reportTaskList.find((task) => task.id === taskId)
      if (task) {
        task.complete.value.current = value.substring(0, value.length - 1)
        task.complete.value.string = value
        task.complete.status = status
      } else {
        console.warn('Task not found: ', taskId)
      }
    },
    resetReportTaskList(state) {
      const updatedTasks = state.reportTaskList.filter(
          (task) =>
              task.complete.status === state.statuses.NEW ||
              task.status.status === state.statuses.NEW ||
              task.general_status.updated_status === state.statuses.NEW
      )
      updatedTasks.forEach((task) => {
        task.initial = {
          status: Object.assign({}, task.status.value),
          general_status: Object.assign({}, task.general_status.status),
          complete: Object.assign({}, task.complete.value)
        }
        task.status.status = state.statuses.INITIAL
        task.general_status.updated_status = state.statuses.INITIAL
        task.complete.status = state.statuses.INITIAL
      })
    },
    setReportMode(state, mode) {
      state.reportMode = mode
    },
    setReportDate(state, dateObject) {
      if (dateObject instanceof Date) {
        state.reportDate = format(dateObject, 'MM/dd/yyyy')
      } else {
        state.reportDate = `${format(
            dateObject.startDate,
            'MM/dd/yyyy'
        )} - ${format(subDays(dateObject.endDate, 1), 'MM/dd/yyyy')}`
      }
    }
  },
  actions: {
    async fetchTimesByWeek({ getters, commit }, week) {
      commit('setViewLoader', true)
      const response = await mtApi.getTimeEntries({
        startDate: week.startDate.toISOString(),
        endDate: week.endDate.toISOString()
      })

      commit('setFolders', {
        folders: response,
        daysWeek: getters.daysWeek,
        status: getters.statuses.INITIAL
      })
      commit('setViewLoader', false)
    },
    async fetchFolders({ commit, state }) {
      if (state.folderList.length !== 0)
        return false

      const response = await mtApi.getFolders();
      commit('setFolderList', response)
      commit('setBackup')
    },
    async submitTimelog({ getters, commit, state }) {
      commit('setViewLoader', true)

      console.log('Submitting time log...', {
        tasks: getters.timeEntryRequestBody.tasks
      })

      let response = null;

      if (getters.timeEntryRequestBody.tasks.length) {
        try {
          response = await mtApi.submitTimelog({
            tasks: getters.timeEntryRequestBody.tasks
          })

          commit('removeEmptyRows', getters.folders)
          commit('resetStatuses', getters.folders)
          commit('replaceInitDuration')

          // resp.tasks.forEach((task) => { // TODO: fix here!
          //   task.time_entries.forEach((time) => {
          //     commit('updateTimeId', {
          //       taskId: task.id,
          //       position: time.position,
          //       id: time.id
          //     })
          //   })
          // })

        } catch (e) {
          setTimeout(() => {
            commit('setViewLoader', false)
          }, 5000)
        } finally {
          if (response && response.message) {
            setTimeout(async () => {
              // const response = await mtApi.getTimeEntries({
              //   startDate: state.week.startDate,
              //   endDate: state.week.endDate
              // })
              //
              // commit('setFolders', {
              //   folders: response,
              //   daysWeek: getters.daysWeek,
              //   status: getters.statuses.INITIAL
              // })

              showSnackbar({
                mode: 'success',
                text: 'Time log has been submitted successfully!'
              })

              commit('setViewLoader', false)
            }, 3000)
          }
        }
      }


      // if (getters.timeEntryRequestBody.tasks.length) {
      //   try {
      //     const response = await Api.clickUp.storeTimeEntries(
      //       getters.timeEntryRequestBody
      //     )
      //
      //     commit('removeEmptyRows', getters.folders)
      //     commit('resetStatuses', getters.folders)
      //     commit('replaceInitDuration')
      //
      //     response.data.forEach((task) => {
      //       task.time_entries.forEach((time) => {
      //         commit('updateTimeId', {
      //           taskId: task.id,
      //           position: time.position,
      //           id: time.id
      //         })
      //       })
      //     })
      //
      //     showSnackbar({
      //       mode: 'success',
      //       text: 'Time log has been submitted successfully!'
      //     })
      //   } finally {
      //     commit('setViewLoader', false)
      //   }
      // }

      // commit('setViewLoader', false)
    },
    async fetchTimesByReportObject({ getters, commit }, interval) {
      commit('setViewLoader', true)
      const response = await mtApi.getTimeEntries(interval);
      commit('setReportTable', response)
      commit('setViewLoader', false)
    },
    async fetchTaskStatuses({ commit }, taskId) {
      commit('setViewLoader', true)
      // const response = await Api.clickUp.fetchTaskStatuses({ id: taskId })
      const response = await mtApi.getTaskStatuses();
      commit('setTaskStatuses', response.custom_field_statuses)
      commit('setGeneralStatuses', response.general_statuses)
      commit('setViewLoader', false)
    }
  },
  getters: {
    folder: (state) => (id) => {
      return state.folders.find((folder) => {
        return folder.id === id
      })
    },
    allTasks(state) {
      return state.folders
          .map((folder) => {
            return folder.tasks
          })
          .flat()
    },
    statuses(state) {
      return state.statuses
    },
    folders(state) {
      return state.folders
    },
    week(state) {
      return state.week
    },
    weekValue(state) {
      return state.weekValue
    },
    daysWeek(state) {
      return [...Array(7).keys()].map((item) => {
        return addDays(startOfDay(Number(state.week.startDate)), item)
      })
    },
    totalCol(state) {
      const summaryEntries = new Array(7).fill(0)
      const tasks = state.folders.map((folder) => {
        return folder.tasks
      })
      tasks.flat().forEach((task) => {
        task.time_entries.forEach((timeEntry, index) => {
          summaryEntries[index] += timeEntry.duration
        })
      })
      return {
        week: summaryEntries,
        total: summaryEntries.reduce((acc, cur) => acc + cur)
      }
    },
    wantedFolders(state) {
      const existedIds = state.folders.map((folder) => {
        return folder.id
      })
      return state.folderList.filter((folder) => {
        return !existedIds.includes(folder.id)
      })
    },
    totalRow(state, getters) {
      return (taskId) => {
        const task = getters.allTasks.find((task) => {
          return task.id === taskId
        })
        return task.time_entries.reduce((acc, cur) => {
          return acc + Number(cur.duration)
        }, 0)
      }
    },
    timeEntryRequestBody(state, getters) {
      const date = new Date()
      let tasks = getters.allTasks.map((task) => {
        const changes = task.time_entries
          .map((time, index) => {
            let result = Object.assign({}, time)
            result.position = index
            // result.at = zonedTimeToUtc(time.at, localStorage.timezone)
            result.at = dayjs(result.at)
              .set('hour', date.getHours())
              .set('minute', date.getMinutes())
              .set('second', date.getSeconds())
              .toDate()


            // result.at = dayjs(result.at).startOf('day').toDate()
            return result
          })
          .filter((item) => {
            return item.status === state.statuses.NEW
          })

        return {
          id: task.id,
          time_entries: changes
        }
      })
      tasks = tasks.filter((task) => {
        return task.time_entries.length
      })
      return { tasks }
    },
    reportTaskList(state) {
      return state.reportTaskList
    },
    newTaskStatuses(state) {
      console.log('newTaskStatuses state', state)
      return state.taskStatuses.map((status) => {
        return {
          code: status.orderindex,
          label: status.name === 'IN' ? status.name : 'IN ' + status.name
        }
      })
    },
    taskStatuses(state) {
      return state.taskStatuses.map((status) => {
        return {
          code: status.orderindex,
          label: status.name === 'IN' ? status.name : 'IN ' + status.name
        }
      })
    },
    generalStatuses(state) {
      return state.generalStatuses;
    },
    timeReportRequestBody(state, getters) {
      if (state.reportMode === 'weekly') {
        const tasks = groupBy(state.reportTaskList, 'folder_id')
        const formatedTasks = Object.keys(tasks).map((key) => {
          const total = _.sumBy(tasks[key], (i) => i.time.value)

          return {
            folder_name: tasks[key][0].folder_name,
            total: TimeConverter.millisecondsToTime(total)
          }
        })
        return { tasks: formatedTasks }
      } else {
        return {
          tasks: state.reportTaskList,
          total: getters.totalReport.string
        }
      }
    },
    totalReport(state) {
      const time = state.reportTaskList.reduce((acc, cur) => {
        const taskTotal = cur.time_entries.reduce((accInner, curInner) => {
          return accInner + Number(curInner.duration)
        }, 0)
        return acc + Number(taskTotal)
      }, 0)
      return {
        number: time,
        string: TimeConverter.millisecondsToTime(time)
      }
    },
    timeReportUpdatedData(state) {
      return state.reportTaskList
          .filter((task) => {
            return (
                task.complete.status === state.statuses.NEW ||
                task.status.status === state.statuses.NEW ||
                task.general_status.updated_status === state.statuses.NEW
            )
          })
          .map((task) => {
            const result = { id: task.id }
            if (task.complete.status === state.statuses.NEW) {
              result.complete = {
                id: task.complete.id,
                value: Number(task.complete.value.current)
              }
            }
            if (task.status.status === state.statuses.NEW) {
              result.status = {
                id: task.status.id,
                value: Number(task.status.value.code)
              }
            }
            if (task.general_status.updated_status === state.statuses.NEW) {
              result.general_status = {
                value: task.general_status.status
              }
            }
            return result
          })
    },
    reportMode(state) {
      return state.reportMode
    },
    reportDate(state) {
      return state.reportDate
    }
  }
}
