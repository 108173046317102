import MtApi, { ContentToolClient } from "@/agGridV2/helpers/mt-api.helper"

export default {
  state: {
    projects: [],
    selectedProject: null
  },
  getters: {
    getProjects(state) {
      return state.projects
    },
    getSelectedProject(state) {
      return state.selectedProject
    },
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects
    },
    setSelectedProject(state, project) {
      state.selectedProject = project
    },
  },
  actions: {
    async loadProjects({commit, state, getters}) {
      if (!getters.me) {
        return false
      }
      try {
        const client = await ContentToolClient.create({ id: getters.me.id, name: getters.me.full_name });
        const projects = await client.projects.get();
        client.destroy();
        const projectsFiltered = [
          {
            _id: 'all',
            name: 'All',
          }
        ]
        for (const { _id, abbr, type, platform } of projects) {
          projectsFiltered.push({
            _id,
            name: `${abbr}-${type} (${platform ? platform.url : 'not found'})`
          })
        }
        commit('setProjects', projectsFiltered)
        if (state.selectedProject) {
          const updatedSelectedProject = projectsFiltered
            .find(project => project._id === state.selectedProject._id)
          if (updatedSelectedProject) {
            commit('setSelectedProject', updatedSelectedProject)
          } else  {
            commit('setSelectedProject', projectsFiltered[0])
          }
        } else {
          commit('setSelectedProject', projectsFiltered[0])
        }
      } catch (error) {
        console.error('Error while getting projects:', error)
      }
    }
  }
}