import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import weeklyLog from './modules/weekly-log'
import teamTimeLog from './modules/team-time-log'
import managementTools from './modules/management-tools'
import leadCenter from './modules/lead-center'
import analytics from './modules/analytics'
import VuexPersist from 'vuex-persist'
import contentTool from './modules/content-tool'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'managementTools',
  storage: window.localStorage,
  modules: ['managementTools', 'leadCenter', 'analytics', 'contentTool']
})

export default new Vuex.Store({
  state: {
    viewLoader: false
  },
  mutations: {
    setViewLoader(state, status) {
      state.viewLoader = status
    }
  },
  getters: {
    viewLoader(state) {
      return state.viewLoader
    }
  },
  modules: {
    user,
    weeklyLog,
    teamTimeLog,
    managementTools,
    leadCenter,
    analytics,
    contentTool
  },
  plugins: [vuexPersist.plugin]
})
